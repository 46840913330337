.expectedReturnWrapper {
  user-select: none;

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-surface) {
    overflow: visible;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-default-legend) {
    position: absolute;
    width: 200px;
  }
}
