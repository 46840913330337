@import '../../assets/styles/shared';

.positiveLabel {
  fill: $green;
}

.negativeLabel {
  fill: $red;
}

.positiveText {
  color: $green;
}

.negativeText {
  color: $red;
}

.selectionLabel {
  fill: $white;
  stroke: rgb(0 0 0 / 10%);
  stroke-width: 1px;
  rx: 5px;
}
