.allocationWrapper {
  user-select: none;

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-surface) {
    overflow: visible;
  }
}

.selectionLabel {
  overflow: visible;
  stroke-width: 1px;
}
