@import '../assets/styles/shared';

$navigation-height: 60px;

.stickyCard {
  position: sticky;
  top: $grid-gutter-width;
  z-index: $zindex-sticky;

  &.withTopFixedNavigation {
    top: $grid-gutter-width + $navigation-height;
  }
}

.stickyHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-sticky;

  width: 100%;
  padding: 1rem;

  color: $white;

  user-select: none;

  background-color: $dark;

  @include media-breakpoint-up(sm) {
    &.withReferralBanner {
      margin-top: 50px;
    }
  }

  &.withReferralBanner {
    margin-top: 55px;
  }

  &.bottomPositioned {
    top: auto !important;
    bottom: 0;
    background-color: $primary;
  }
}
