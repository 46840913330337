@use 'sass:map';

@import '../assets/styles/shared';
@import './Circle.module';

$image-upload-height: '#{$btn-padding-y * 2} + #{$btn-border-width} + 12px';
$max-width: 640px;
$timeout: 300ms;
$easing: $easeInOutQuad;

:export {
  timeout: $timeout;
  /* stylelint-disable-next-line value-keyword-case */
  easing: easeInOutQuad;
  borderWidth: strip-unit($card-border-width);
}

.container {
  max-width: $max-width + 2 * $card-border-width;
}

.imageInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagePreview {
  align-self: center;

  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.imageUpload {
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 0;

  .imageUploadInput {
    position: absolute;
    z-index: -1;

    width: 100%;
    height: calc(#{$image-upload-height});
    margin: 0;

    opacity: 0;
  }

  .imageUploadLabel {
    margin-bottom: 0;
    cursor: pointer;
  }
}

.btnCancel {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.btnCapture {
  position: absolute;
  right: 0;
  bottom: map.get($circle-sizes, 'sm') * -0.5;
  left: 0;

  margin-right: auto;
  margin-left: auto;
}

.btnCaptureAppearActive {
  bottom: calc(50% - #{map.get($circle-sizes, 'sm') * 0.5}) !important;
  transition: bottom $timeout $easing;
}

.btnCaptureAppearDone {
  bottom: calc(50% - #{map.get($circle-sizes, 'sm') * 0.5}) !important;
}
