@import '../../../styles/shared';

.hidden {
  visibility: hidden;
}

// TODO: Remove in refactoring and use Transition component
.header {
  transition: margin 750ms $easeInOutQuad;

  @include media-breakpoint-up(sm) {
    &.referralBannerOffset {
      margin-top: 50px;
    }
  }

  &.referralBannerOffset {
    margin-top: 55px;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
