@import '../../../../../styles/shared';

.result-card-content {
  display: flex;

  align-items: center;

  justify-content: space-between;

  width: 100%;
  height: 100%;
}

.result-card-elaborate {
  display: flex;

  flex-direction: column;

  justify-content: center;

  width: 100%;
  height: 100%;
}

.risk-profile {
  font-size: 2rem;
}

.chart {
  width: 100%;
  height: 100%;
}

.dot {
  display: inline-block;

  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.125rem;

  border-radius: 100%;
}

.risk-score {
  height: 100%;

  @include media-breakpoint-up(lg) {
    top: $grid-gutter-width / 2;
  }

  .risk-score-inside {
    position: absolute;
    width: 100%;
    margin-top: 10px;
  }

  .risk-score-text {
    user-select: none;
  }
}

.result-section {
  margin-bottom: $grid-gutter-width;

  .section-inner {
    padding: $grid-gutter-width / 2;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}

.vertical-line {
  border-left: 1px solid $gray-100;
}

.risk-score-absolute {
  position: absolute;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;

  display: flex;

  align-items: center;

  justify-content: space-between;

  padding: 0.25rem;

  color: $yiq-text-dark;

  background: $nord-blue;
  border-bottom: rgb(255 255 255 / 10%) 1px solid;

  .fixed-risk-score {
    text-align: center;

    // @include text-monospace;
    // font-size: $font-size-sm;
  }
}

.fixed-footer-container {
  margin-top: 59 + ($grid-gutter-width / 2);
}

.fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;

  padding: 0.25rem;

  background: $nord-blue;
  border-top: rgb(255 255 255 / 10%) 1px solid;

  .up-icon {
    padding-top: 13px;
    padding-bottom: 0;
  }
}
