@import '../../styles/shared';

.nested-fields-container {
  padding: 15px;
  margin-bottom: $form-group-margin-bottom;

  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 4px;
  box-shadow: $box-shadow;

  &.is-invalid {
    border-color: $form-feedback-invalid-color;
  }

  &.is-valid {
    border-color: $form-feedback-valid-color;
  }

  .form-group:last-child {
    margin: 0;
  }
}

@include media-breakpoint-up(sm) {
  .nested-field {
    padding-left: 15px;
    border-left: 1px solid $gray-100;
  }

  .nested-field-line {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .nested-field-line {
    height: 1em;
    margin-bottom: 1em;
    margin-left: 5px;
    border-left: 1px solid $gray-100;
  }
}
