@import '../../assets/styles/shared';

.riskScoreHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.riskScoreText {
  position: absolute;
  bottom: 0;

  width: 100%;

  text-align: center;

  pointer-events: none;
  user-select: none;

  svg {
    pointer-events: all;
    cursor: pointer;
  }
}
