@import '../../../../../styles/shared';

$animation-timeout-factor: 1;
$timeout: 500ms * $animation-timeout-factor;
$button-container-height: 0.5rem + 2.875rem + 0.25rem;

:export {
  timeout: $timeout;
}

.documentsModal {
  .documentsArrow {
    position: absolute;
    top: 50%;

    &.documentsArrowLeft {
      left: -150px;
    }

    &.documentsArrowRight {
      right: -150px;
    }
  }

  .documentWrapper {
    width: 100%;
    height: calc(100vh - 25rem);
    min-height: 300px;
  }
}

.documentViewer {
  background-color: $white;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.documentsModal {
  .documentSwitch {
    &.documentSwitchEnterActive {
      transition: transform $timeout $easeOutQuint, opacity $timeout $easeOutQuint;
    }

    &.documentSwitchExitActive {
      transition: transform $timeout $easeInQuint, opacity $timeout $easeInQuint;
    }

    &.documentSwitchEnter.documentSwitchEnterActive,
    &.documentSwitchExit {
      opacity: 1;
      transform: translate(0%, 0%);
    }
  }
}

.documentsModal,
.documentContent {
  &:not(.documentSwitchReverse) {
    .documentSwitch {
      &.documentSwitchEnter {
        opacity: 0;
        transform: translate(100%, 0%);
      }

      &.documentSwitchExit.documentSwitchExitActive {
        opacity: 0;
        transform: translate(-100%, 0%);
      }
    }
  }

  &.documentSwitchReverse {
    .documentSwitch {
      &.documentSwitchEnter {
        opacity: 0;
        transform: translate(-100%, 0%);
      }

      &.documentSwitchExit.documentSwitchExitActive {
        opacity: 0;
        transform: translate(100%, 0%);
      }
    }
  }
}
