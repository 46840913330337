@import '../../../../../../styles/shared';

.slide-down {
  &.slide-down-hide {
    max-height: 0;
    overflow: hidden;
  }

  &.slide-down-enter {
    max-height: 0;
    overflow: hidden;
  }

  &.slide-down-enter-active {
    max-height: 285px;
    transition: max-height 0.5s $easeInOutQuad;
  }

  &.slide-down-exit {
    max-height: 285px;
    overflow: hidden;
  }

  &.slide-down-exit-active {
    max-height: 0;
    transition: max-height 0.5s $easeInOutQuad;
  }

  &.slide-down-exit-done {
    overflow: hidden;
  }
}
