@import '../../assets/styles/shared';

$custom-font-size: 16px !important;

.chartTitle {
  font-size: 1.5em !important;
}

.historicalReturnWrapper {
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-area-curve) {
    mask: none !important;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-legend-wrapper) {
    @include media-breakpoint-down(md) {
      height: 50px !important;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.rechart-brush-traveller) {
    /* stylelint-disable-next-line max-nesting-depth */
    rect {
      @include media-breakpoint-down(md) {
        width: 15px !important;
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-brush-texts) {
    text-transform: capitalize;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-surface) {
    overflow: visible;
    font-size: $custom-font-size;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.recharts-tooltip-wrapper) {
    font-size: $custom-font-size;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global(.table-bordered td) {
    @include media-breakpoint-up(sm) {
      width: 50% !important;
    }
  }
}
