@import '../../styles/shared';

.page {
  transition: margin 750ms $easeInOutQuad;

  @include media-breakpoint-up(sm) {
    &.referralBannerOffset {
      margin-top: 50px;
    }
  }

  &.referralBannerOffset {
    margin-top: 55px;
  }
}
