.tooltip {
  border: 1px solid rgb(0 0 0 / 10%);

  .tooltipBody {
    padding: 10px 18px;

    /* stylelint-disable-next-line max-nesting-depth */
    .tooltipTitle {
      margin-bottom: 0;
    }
  }
}
