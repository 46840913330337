// TODO: remove all (or most), and sync with storybook

@use 'sass:math';

@import './variables';

$exit-animation: $easeInQuad;
$enter-animation: $easeOutQuad;
$timeout: 300ms;

html {
  scroll-behavior: smooth;
}

main {
  display: block;
}

.static {
  position: fixed;
  top: 0;

  width: 100%;
  height: 402px;

  background: linear-gradient(0deg, $dusk-blue 0%, $nord-blue 100%);
}

.hero {
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;

  @include media-breakpoint-up(sm) {
    padding: 2.25rem 0;
  }

  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }

  .logo {
    text-align: center;

    img {
      width: 97px;

      @include media-breakpoint-up(sm) {
        width: 109px;
      }

      @include media-breakpoint-up(md) {
        width: 121px;
      }
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  margin-bottom: $grid-gutter-width;
}

// TODO:
// .navigation-buttons {
//   margin-left: 84px;
//   margin-right: 84px;
//   flex-wrap: wrap-reverse;
// }

// .h2, h2 {
//   font-size: 1.5rem;
// }

dt {
  @include text-uppercase;

  font-weight: normal;
  opacity: (math.div(2, 3));
}

dd,
dt {
  font-size: $font-size-sm;
}

dl {
  margin-bottom: 0;
}

dd:last-child {
  margin-bottom: 0;
}

.progress-bar-wrapper {
  position: relative;

  width: 100%;
  max-width: 550px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  background: rgb(255 255 255 / 50%);
  border-radius: 3px;

  .progress-bar {
    position: relative;

    display: block;

    height: 100%;
    overflow: hidden;

    background: white;

    transition: transform ($timeout * 2) $easeInOutQuad;
  }
}

.page-container {
  font-size: 13px; // TODO
  text-align: center;
  opacity: 0.67;
}

.logo-wrapper {
  display: flex;
  justify-content: center;

  .logo-inner {
    align-self: center;
    padding: 20px;
  }
}

.rounded-cut {
  position: absolute;
  bottom: 0;
}

.animate-right {
  position: relative;

  &.animate-enter,
  &.animate-appear {
    right: 200px;
    opacity: 0;
  }

  &.animate-enter-active,
  &.animate-appear-active {
    right: 0;
    opacity: 1;
    transition: right $timeout $enter-animation, opacity $timeout $enter-animation;
  }

  &.animate-exit {
    right: 0;
    opacity: 1;
  }

  &.animate-exit-active {
    right: -200px;
    opacity: 0;
    transition: right $timeout $exit-animation, opacity $timeout $exit-animation;
  }

  &.animate-exit-done {
    opacity: 0;
  }
}

.animate-left {
  position: relative;

  &.animate-enter,
  &.animate-appear {
    left: 200px;
    opacity: 0;
  }

  &.animate-enter-active,
  &.animate-appear-active {
    left: 0;
    opacity: 1;
    transition: left $timeout $enter-animation, opacity $timeout $enter-animation;
  }

  &.animate-exit {
    left: 0;
    opacity: 1;
  }

  &.animate-exit-active {
    left: -200px;
    opacity: 0;
    transition: left $timeout $exit-animation, opacity $timeout $exit-animation;
  }

  &.animate-exit-done {
    opacity: 0;
  }
}

.card-arrow {
  .arrow {
    position: absolute;

    display: block;

    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::before,
    &::after {
      position: absolute;

      display: block;

      content: '';

      border-color: transparent;
      border-style: solid;
    }
  }
}

.card-arrow-bottom {
  margin-top: $popover-arrow-height;

  .arrow {
    top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
  }

  .arrow::before,
  .arrow::after {
    border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
  }

  .arrow::before {
    top: 0;
    border-bottom-color: $popover-arrow-outer-color;
  }

  .arrow::after {
    top: $popover-border-width;
    border-bottom-color: $popover-arrow-color;
  }
}

.modal {
  .modal-header,
  .modal-footer {
    border: none;

    // padding: 0;
  }

  .modal-header {
    .close {
      position: absolute;
      right: 1rem;
    }
  }
}

.btn {
  white-space: normal;
}

.input-group {
  box-shadow: $input-box-shadow;

  .form-control {
    box-shadow: none;
  }
}

.text-base {
  @include text-base;

  font-size: 1rem;
}

.form-control-boolean {
  height: auto !important;
  min-height: $input-height;
}

.input-group.is-invalid {
  .input-group-text,
  .form-control {
    // stylelint-disable scss/at-extend-no-missing-placeholder

    @extend .is-invalid;
    // stylelint-enable scss/at-extend-no-missing-placeholder
  }
}

.input-group.is-valid {
  .input-group-text,
  .form-control {
    // stylelint-disable scss/at-extend-no-missing-placeholder

    @extend .is-valid;
    // stylelint-enable scss/at-extend-no-missing-placeholder
  }
}

.invalid-feedback {
  display: block;
}

ol.list-alpha {
  counter-reset: alpha;

  & > li {
    list-style: none;

    // position: relative;

    &::before {
      content: counter(alpha, upper-alpha) ') ';
      counter-increment: alpha;
    }
  }
}

#outdated .btn {
  margin: 30px auto 0;
}

// Fix scrolling issue on iOS
body {
  -webkit-overflow-scrolling: touch;
}
