@import '../../../styles/shared';

.referralWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index-layer($overlay-layer-index + 2);

  width: 100vw;
  height: 55px;
  overflow: hidden;

  // TODO: ReferralBanner could be made with Transition component from storybook
  transition: height 750ms $easeInOutQuad;

  @include media-breakpoint-up(sm) {
    height: 50px;
  }

  &.invalidReferral {
    height: 0 !important;
  }
}

// TODO: Move this styling to PageBanner component

.referralBanner {
  position: absolute;

  inset: 0;

  display: flex;

  align-items: center;

  justify-content: center;
}
