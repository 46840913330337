@import '../../assets/styles/shared';

$timeout: 750ms * $animationTimeoutFactor;
$easing-in: $easeInQuint;
$easing-out: $easeOutQuint;

:export {
  timeout: strip-unit($timeout);
}

.collapseContainer {
  position: relative;
  z-index: z-index-layer($overlay-layer-index + 1, $zindex-fixed);

  width: 100%;
  margin-top: 0;
  overflow: hidden;

  &.collapseExitActive,
  &.collapseExitDone {
    z-index: z-index-layer($overlay-layer-index, $zindex-fixed);
    margin-top: -3.825rem;

    @include media-breakpoint-up(md) {
      margin-top: -3rem;
    }
  }

  &.collapseExitActive {
    transition: margin-top $timeout $easing-out;
  }
}

.pageBanner {
  @include text-uppercase;

  width: 100%;
  padding: 0.75em;
  font-size: 0.85em;
  text-align: center;

  @include media-breakpoint-down(md) {
    :global(button.close) {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    z-index: $zindex-fixed;
    padding: 0.75em 3em;
    font-size: 1em;
  }
}
