@use 'sass:color';

@import '../../../../styles/shared';

.providerRow > th,
.providerRow > td {
  border-width: 5px !important;
}

.providerTable {
  border-bottom: 5px solid $table-border-color;
}

.referenceNumber {
  font-weight: normal !important;
}

.imgContainer {
  min-width: 100px;
  max-width: 100px;

  .imgResponsive {
    max-height: 50px;
  }
}

.pensionsList {
  width: calc(100% + #{$card-spacer-x * 2});
  margin-right: -$card-spacer-x;
  margin-left: -$card-spacer-x;

  @include media-breakpoint-down(sm) {
    width: calc(100% + #{$card-spacer-x});
    margin-right: -$card-spacer-x / 2;
    margin-left: -$card-spacer-x / 2;
  }
}

.pensionProviderRow {
  height: calc(50px + 1rem);
  padding: 0.5rem calc(#{$card-spacer-x} - #{$grid-gutter-width});
  margin: 0;
  border-top: $table-border-width solid $table-border-color;
}

.pensionProviderEntry {
  height: 50px;
  padding-right: $card-spacer-x;
  padding-left: $card-spacer-x;
  border-top: $table-border-width solid $table-border-color;
}

.disabledPensionProviderRow {
  color: $text-muted;
  white-space: nowrap;

  img {
    filter: saturate(0);
  }

  > th,
  > td {
    border-color: color.adjust($light, $lightness: -5);
  }
}
