@import '../../styles/shared';

.breadcrumbs-wrapper {
  text-align: center;

  .breadcrumbs {
    // @include text-monospace;

    @include text-uppercase;

    // font-size: $font-size-ba;
    display: inline-block;
    margin-bottom: 0;
    color: #fff;

    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }

    li {
      float: left;
      margin: 0 24px;

      &:not(.active) {
        opacity: 0.66;
      }
    }
  }
}
