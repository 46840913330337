@use 'sass:string';

@import '../../assets/styles/shared';

$svg-transition: string.unquote(
  str-replace(
    str-replace($custom-forms-transition, 'box-shadow', 'stroke-width'),
    'background-color',
    'fill'
  )
);

:export {
  offset: strip-unit(($custom-range-thumb-width * 0.5) + $input-btn-focus-width) * 16;
}

.customRangeRound {
  .customRangeTrack {
    cursor: $custom-range-track-cursor;
    user-select: none;
    stroke: $custom-range-track-bg;
    stroke-width: $custom-range-track-height;
  }

  .customRangeFill {
    cursor: $custom-range-track-cursor;
    user-select: none;
    stroke: theme-color('primary');
    stroke-width: $custom-range-track-height;
  }

  .customRangeHandleWrapper {
    .customRangeHandle {
      @include transition($svg-transition);

      width: $custom-range-thumb-width;
      height: $custom-range-thumb-height;

      touch-action: none;
      cursor: $custom-range-track-cursor;
      user-select: none;

      fill: $custom-range-thumb-bg;
      stroke: $input-border-color;
      stroke-width: $input-border-width;
    }

    .customRangeHandleShadow {
      @include transition($svg-transition);

      user-select: none;
      fill: none;
      stroke: $input-btn-focus-color;
      stroke-width: 0;
    }

    &:active {
      .customRangeHandle {
        fill: $custom-range-thumb-active-bg;
      }
    }

    &:focus {
      outline: none;

      .customRangeHandleShadow {
        stroke-width: $input-btn-focus-width * 2;
      }
    }
  }
}
